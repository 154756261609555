























import Message from "@/components/Message.vue";
import IButton from "@/interfaces/IButton";
import { computed, defineComponent } from "@vue/composition-api";

export default defineComponent({
  components: { Message },
  setup(_, { root }) {
    return {
      primaryButton: computed<IButton>(() => {
        return {
          title: root.$tc("common.signin").toString(),
          variant: "primary",
          onClick: () => root.$router.push("/"),
        };
      }),
    };
  },
});
